@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: 'Londrina';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url('fonts/Londrina/LondrinaSolid-Regular.ttf');
}

@font-face {
	font-family: 'Londrina';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url('fonts/Londrina/LondrinaSolid-Light.ttf');
}

@font-face {
	font-family: 'Londrina';
	font-style: normal;
	font-weight: 100;
	font-display: swap;
	src: url('fonts/Londrina/LondrinaSolid-Thin.ttf');
}

@font-face {
	font-family: 'Londrina';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url('fonts/Londrina/LondrinaSolid-Black.ttf');
}

html {
	font-family: sans-serif;
	overflow: hidden;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
